<template>
  <div>
    <div v-for="(item, index) in levelList" :key="item.path">
      <a
        v-if="item.redirect !== 'noredirect' && index !== levelList.length - 1"
        @click.prevent="handleLink(item)"
      >
        <i class="el-icon-arrow-left navbar-icon brand-color" style="padding-left: 15px;" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {
      levelList: null
    }
  },
  computed: {
    currentPath() {
      return this.$route.path
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name)
      this.levelList = matched.filter(
        (item) =>
          item.meta &&
          item.meta.title &&
          item.meta.breadcrumb !== false &&
          item.redirect !== this.currentPath
      )
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = path
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
