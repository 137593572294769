import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/lib/theme-chalk/index.css'
import store from './store'
import router from './router'

import CKEDITOR from '@ckeditor/ckeditor5-vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import '@/styles/index.scss' // global css

import './icons' // icon
import './permission' // permission control
// import './utils/error-log' // error log

import SvgIcon from '@/components/SvgIcon'// svg component
import installElementPlus from './plugins/element'

const app = createApp(App)

app.config.productionTip = false

// register globally
app.component('SvgIcon', SvgIcon)
app.use(CKEDITOR)
installElementPlus(app)
app.use(store)
app.use(router)
app.mount('#app')

export default app
