import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/me',
    method: 'get'
  })
}

export function refreshToken(data) {
  return request({
    url: '/refresh',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function getCustomerInfo(userId) {
  return request({
    url: '/user/' + userId + '/info',
    method: 'get'
  })
}
