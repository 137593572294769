import ElementPlus from 'element-plus'
import '../styles/element-variables.scss'
import locale from 'element-plus/lib/locale/lang/ja'
import Cookies from 'js-cookie'

export default (app) => {
  app.use(ElementPlus, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    locale: locale
  })
}
