import axios from 'axios'
import {
  ElMessage
  // ElMessageBox
} from 'element-plus'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const status = response.status
    const res = response.data

    const statusPass = [200, 201, 204]

    // if the custom code is not 20000, it is judged as an error.
    if (!statusPass.includes(status)) {
      ElMessage({
        message: 'エラーが発生しました',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: '再ログイン',
      //     cancelButtonText: 'キャンセル',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    const res = error.response
    const status = res.status
    const result_code = res.data.result_code

    console.log('Err service: ', error.message) // for debug
    // console.log(res) // error

    const errToken = ['PERMISSION_DENIED', 'AUTHENTICATE_ERROR']

    if (status !== 401 && !errToken.includes(result_code)) {
      if (result_code === 'EMAIL_EXISTS') {
        ElMessage({
          message: 'メールは使用されました',
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        ElMessage({
          message: 'エラーが発生しました',
          type: 'error',
          duration: 5 * 1000
        })
      }
    }

    if (status === 401 && res.config.url === '/login') {
      ElMessage({
        message: 'エラーが発生しました',
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
