import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

import Layout from '@/layout'
/**
 * constantRoutes
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: () => import('@/layout/index'),
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },

  {
    path: '/privacy',
    component: () => import('@/views/privacy/index'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/news',
    hidden: true
  },

  {
    path: '/news',
    component: Layout,
    redirect: '/news',
    name: 'News',
    meta: { title: 'ニュース' },
    // expand: true,
    children: [
      {
        path: '',
        name: 'NewsList',
        component: () => import('@/views/news/index'),
        meta: { title: 'ニュース' }
      },
      {
        path: ':id/edit',
        name: 'EditNews',
        component: () => import('@/views/news/edit'),
        meta: { title: 'ニュース・変更' },
        hidden: true
      },
      {
        path: 'create',
        name: 'CreateNews',
        component: () => import('@/views/news/create'),
        meta: { title: 'ニュース・追加' },
        hidden: true
      }
    ]
  },

  {
    path: '/chat',
    component: Layout,
    redirect: '/chat',
    name: 'Chat',
    meta: { title: 'Chat' },
    // expand: true,
    children: [
      {
        path: '',
        name: 'ChatRooms',
        component: () => import('@/views/chat/Index'),
        meta: { title: 'チャット' }
      },
      {
        path: 'room/:id',
        name: 'ChatRoomDetail',
        component: () => import('@/views/chat/room/Index'),
        meta: { title: 'チャット' },
        hidden: true
      }
    ]
  },

  {
    path: '/interviews',
    component: Layout,
    name: 'Interview',
    redirect: '/interviews',
    meta: { title: 'Interview' },
    children: [
      {
        path: '',
        name: 'InterviewList',
        component: () => import('@/views/interview/index'),
        meta: { title: 'インタビュー' }
      },
      {
        path: ':id/edit',
        name: 'EditInterview',
        component: () => import('@/views/interview/edit'),
        meta: { title: 'インタビュー・変更' },
        hidden: true
      },
      {
        path: 'create',
        name: 'CreateInterview',
        component: () => import('@/views/interview/create'),
        meta: { title: 'インタビュー・追加' },
        hidden: true
      }
    ]

  },

  {
    path: '/training',
    component: Layout,
    name: 'Training',
    redirect: '/training',
    meta: { title: 'Training' },
    expand: true,
    children: [
      {
        path: '',
        name: 'TrainingList',
        component: () => import('@/views/training/index'),
        meta: { title: 'トレーニング' }
      },
      {
        path: ':id/edit',
        name: 'EditTraining',
        component: () => import('@/views/training/edit'),
        meta: { title: 'トレーニング' },
        hidden: true
      },
      {
        path: 'create',
        name: 'CreateTraining',
        component: () => import('@/views/training/create'),
        meta: { title: 'トレーニング' },
        hidden: true
      },
      {
        path: ':id/video-admin',
        name: 'VideoTraining',
        component: () => import('@/views/training/video'),
        meta: { title: 'トレーニング' },
        hidden: true
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile',
    children: [
      {
        path: '',
        name: 'Profile',
        component: () => import('@/views/profile/index'),
        meta: { title: 'プロフィール' }
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('@/views/profile/change-password'),
        meta: { title: 'パスワードの変更' }
      }
    ],
    hidden: true
  },

  // 404 page must be placed at the end !!!
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = []

const createCustomRouter = () => createRouter({
  // mode: 'history', // require service support
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createCustomRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createCustomRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
